import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import InterfaceController from '../InterfaceController';
import PPNode from '../classes/NodeClass';
import ResponsiveDrawer from './ResponsiveDrawer';
import { DEFAULT_DRAWER_WIDTH } from '../utils/constants';

type GraphOverlayDrawerProps = {
  randomMainColor: string;
  selectedNodes: PPNode[];
  toggle: boolean;
  toggleLeft: boolean;
};

const GraphOverlayDrawer: React.FunctionComponent<GraphOverlayDrawerProps> = (
  props,
) => {
  const [leftDrawerWidth, setLeftDrawerWidth] = useState(DEFAULT_DRAWER_WIDTH);
  const [rightDrawerWidth, setRightDrawerWidth] =
    useState(DEFAULT_DRAWER_WIDTH);

  useEffect(() => {
    InterfaceController.onDrawerSizeChanged(
      props.toggleLeft ? leftDrawerWidth : 0,
      false ? rightDrawerWidth : 0,
    );
  }, [rightDrawerWidth, leftDrawerWidth, props.toggle, props.toggleLeft]);

  return (
    <Box>
      <ResponsiveDrawer
        isLeft={true}
        drawerWidth={leftDrawerWidth}
        setDrawerWidth={setLeftDrawerWidth}
        toggle={props.toggleLeft}
        selectedNodes={props.selectedNodes}
        randomMainColor={props.randomMainColor}
      />
      <ResponsiveDrawer
        isLeft={false}
        drawerWidth={rightDrawerWidth}
        setDrawerWidth={setRightDrawerWidth}
        toggle={props.toggle}
        selectedNodes={props.selectedNodes}
        randomMainColor={props.randomMainColor}
      />
    </Box>
  );
};

export default GraphOverlayDrawer;
