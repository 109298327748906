import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Box, Button, Drawer } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import Socket from '../classes/SocketClass';
import NodeInspectorContainer from '../NodeInspectorContainer';
import GraphInspectorContainer from '../GraphInspectorContainer';
import LeftsideContainer from '../LeftsideContainer';
import { useIsSmallScreen } from '../utils/utils';
import { TRgba } from '../utils/interfaces';
import PPGraph from '../classes/GraphClass';
import * as styles from '../utils/style.module.css';

function DrawerToggleInspector(props) {
  return (
    <Box id="drawer-toggle-inspector" data-cy="inspector-container">
      <Button
        data-cy="inspector-container-toggle-button"
        title={`${props.open ? 'Close node inspector' : 'Open node inspector'}`}
        size="small"
        onClick={() => {
          InterfaceController.toggleRightSideDrawer();
        }}
        sx={{
          position: 'fixed',
          bottom: '16px',
          right: '16px',
          width: '32px',
          minWidth: '32px',
          zIndex: 'unset',
        }}
      >
        <TuneIcon />
      </Button>
    </Box>
  );
}

function DrawerToggleLeftside(props) {
  return (
    <Box id="drawer-toggle-leftside">
      <Button
        size="small"
        title={`${props.open ? 'Close' : 'Open playground list'}`}
        onClick={() => {
          InterfaceController.toggleLeftSideDrawer();
        }}
        sx={{
          display: props.open ? 'none' : 'unset',
          position: 'fixed',
          bottom: '16px',
          left: '16px',
          width: '32px',
          height: '32px',
          minWidth: '32px',
          zIndex: '10',
        }}
      >
        <VerticalSplitIcon />
      </Button>
    </Box>
  );
}

const ResponsiveDrawer = (props) => {
  const [nodeFilter, setNodeFilter] = useState(null);
  const [leftsideFilter, setLeftsideFilter] = useState('graphs');
  const [graphFilter, setGraphFilter] = useState('nodes');
  const [graphFilterText, setGraphFilterText] = useState('');
  const [socketToInspect, setSocketToInspect] = useState<Socket | undefined>(
    undefined,
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const drawerRef = useRef(null);
  const timerRef = useRef(null);
  const smallScreen = useIsSmallScreen();

  const tooltipInspectorToggled = () => {
    setSocketToInspect(PPGraph.currentGraph.socketToInspect);
  };

  useEffect(() => {
    if (!props.isLeft) {
      const ids = [];
      ids.push(
        InterfaceController.addListener(
          ListenEvent.ToggleTooltipInspector,
          tooltipInspectorToggled,
        ),
      );

      return () => {
        ids.forEach((id) => InterfaceController.removeListener(id));
      };
    }
  }, [props.isLeft]);

  useEffect(() => {
    if (props.toggle) {
      setIsPinned(true);
      setIsOpen(true);
    } else {
      setIsPinned(false);
      setIsOpen(false);
    }
  }, [props.toggle]);

  const clearDrawerTimer = () => {
    clearTimeout(timerRef.current);
  };

  const setDrawerTimer = (callback, delay) => {
    clearDrawerTimer();
    timerRef.current = setTimeout(callback, delay);
  };

  const DRAWER_CLOSE_DELAY_MS = 100;
  const DRAWER_OPEN_DELAY_MS = 80;

  const handleDrawer = (e) => {
    const hotZoneWidth = 30;
    const screenWidth = window.innerWidth;
    const isInHotZone = props.isLeft
      ? e.clientX <= hotZoneWidth
      : e.clientX >= screenWidth - hotZoneWidth;

    if (isOpen && drawerRef.current) {
      const drawerRect = drawerRef.current.getBoundingClientRect();
      const isOutsideDrawer =
        e.clientX < drawerRect.left ||
        e.clientX > drawerRect.right ||
        e.clientY < drawerRect.top ||
        e.clientY > drawerRect.bottom;

      if (isOutsideDrawer) {
        setDrawerTimer(() => {
          setIsPinned(false);
          setIsOpen(false);
        }, DRAWER_CLOSE_DELAY_MS);
      } else {
        clearDrawerTimer();
      }
    } else if (!isOpen && isInHotZone) {
      setDrawerTimer(() => setIsOpen(true), DRAWER_OPEN_DELAY_MS);
    } else {
      clearDrawerTimer();
    }
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (smallScreen || isPinned) return;
      handleDrawer(e);
    };

    if (!smallScreen) {
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      clearDrawerTimer();
    };
  }, [isPinned, isOpen, props.isLeft, smallScreen]);

  const handleMouseDown = (e) => {
    if (isPinned) {
      document.addEventListener('pointerup', handlePointerUp, true);
      document.addEventListener('pointermove', handlePointerMove, true);
    }
  };

  const handlePointerUp = () => {
    document.removeEventListener('pointerup', handlePointerUp, true);
    document.removeEventListener('pointermove', handlePointerMove, true);
  };

  const handlePointerMove = useCallback(
    (e) => {
      if (!isPinned) return;

      const minDrawerWidth = 50;
      const maxDrawerWidth = window.innerWidth - 100;
      const newWidth = props.isLeft
        ? e.clientX - 8
        : document.body.offsetWidth - e.clientX + 20;

      if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
        requestAnimationFrame(() => {
          props.setDrawerWidth(newWidth);
        });
      }
    },
    [props, isPinned],
  );

  const handlePin = () => {
    if (props.isLeft) {
      InterfaceController.toggleLeftSideDrawer(!isPinned);
    }
    setIsPinned(!isPinned);
  };

  const handleClose = () => {
    if (!isPinned) {
      props.onClose();
    }
  };

  const margin = 8;

  return (
    <>
      {!smallScreen &&
        (props.isLeft ? <DrawerToggleLeftside /> : <DrawerToggleInspector />)}
      {props.isLeft && (
        <Box
          className={isOpen ? styles.fadeEnter : styles.fadeExit}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100vh',
            pointerEvents: 'none',
            border: `8px solid ${TRgba.fromString(props.randomMainColor).setAlpha(0.98)}`,
            zIndex: 10,
            boxShadow: 'inset 0px 0px 40px 0px rgba(0,0,0,0.3)',
          }}
        />
      )}
      <Drawer
        anchor={props.isLeft ? 'left' : 'right'}
        variant="persistent"
        hideBackdrop={true}
        open={isOpen}
        onClose={handleClose}
        transitionDuration={{
          enter: 150,
          exit: 100,
        }}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          elevation: 0,
          style: {
            zIndex: props.isLeft ? 10 : 4,
            width: smallScreen ? '100%' : props.drawerWidth,
            border: 0,
            background: `${TRgba.fromString(props.randomMainColor).setAlpha(0.98)}`,
            height: smallScreen ? '100vh' : `calc(100vh - ${margin * 2}px)`,
            marginTop: smallScreen ? 0 : `${margin}px`,
            marginRight: smallScreen ? 'unset' : `${margin}px`,
          },
          ref: drawerRef,
        }}
      >
        {isPinned && (
          <div
            onMouseDown={(e) => handleMouseDown(e)}
            className={props.isLeft ? styles.draggerLeft : styles.dragger}
          ></div>
        )}
        {props.isLeft ? (
          <LeftsideContainer
            filter={leftsideFilter}
            setFilter={setLeftsideFilter}
            randomMainColor={props.randomMainColor}
            handlePin={handlePin}
            isPinned={isPinned}
          />
        ) : props.selectedNodes.length ? (
          <NodeInspectorContainer
            selectedNodes={props.selectedNodes}
            socketToInspect={socketToInspect}
            randomMainColor={props.randomMainColor}
            filter={nodeFilter}
            setFilter={setNodeFilter}
            setSocketToInspect={setSocketToInspect}
            handlePin={handlePin}
            isPinned={isPinned}
          />
        ) : (
          <GraphInspectorContainer
            selectedNodes={props.selectedNodes}
            randomMainColor={props.randomMainColor}
            filter={graphFilter}
            setFilter={setGraphFilter}
            filterText={graphFilterText}
            setFilterText={setGraphFilterText}
            handlePin={handlePin}
            isPinned={isPinned}
          />
        )}
      </Drawer>
    </>
  );
};

// not neccessary to memoize this for the moment, but can be relevant later so leaving this uncommented
export default React.memo(ResponsiveDrawer, (prevProps, newProps) => {
  return (
    prevProps.selectedNodes === newProps.selectedNodes &&
    prevProps.drawerWidth === newProps.drawerWidth &&
    prevProps.toggle === newProps.toggle
  );
});
