import React from 'react';
import { CodeWidget } from '../../widgets';
import {
  AbstractType,
  Compatibility,
  CompatibilityType,
  DataTypeProps,
} from './abstractType';
import { AnyType } from './anyType';

export interface CodeTypeProps extends DataTypeProps {
  dataType: AbstractType;
}

export class CodeType extends AbstractType {
  constructor() {
    super();
  }

  getName(): string {
    return 'Code';
  }

  protected dataIsCompatible(
    data: any,
    convertFrom: AbstractType,
  ): Compatibility {
    return typeof data == 'string'
      ? new Compatibility(CompatibilityType.Compatible)
      : new Compatibility(CompatibilityType.Incompatible);
  }

  getInputWidget = (props: CodeTypeProps): any => {
    props.dataType = this;
    return <CodeWidget {...props} />;
  };

  getDefaultWidgetSize(): any {
    return {
      w: 4,
      h: 6,
      minW: 2,
      minH: 2,
    };
  }

  getDefaultValue(): any {
    return '';
  }

  recommendedOutputNodeWidgets(): string[] {
    return ['CodeEditor'];
  }

  recommendedInputNodeWidgets(): string[] {
    return ['CodeEditor', 'Constant'];
  }
}
