/* eslint-disable @typescript-eslint/no-explicit-any */
import * as PIXI from 'pixi.js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import DataEditor, {
  CellClickedEventArgs,
  DataEditorRef,
  EditableGridCell,
  GridCell,
  GridCellKind,
  GridColumn,
  GridMouseEventArgs,
  HeaderClickedEventArgs,
  Item,
  Rectangle,
} from '@glideapps/glide-data-grid';
import '@glideapps/glide-data-grid/dist/index.css';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Menu,
  Paper,
  Popper,
  ThemeProvider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import SortIcon from '@mui/icons-material/Sort';
import PPSocket from '../../classes/SocketClass';
import { sortCompare } from '../../utils/utils';
import {
  NODE_TYPE_COLOR,
  SOCKET_TYPE,
  customTheme,
} from '../../utils/constants';
import { TNodeSource, TRgba } from '../../utils/interfaces';
import HybridNode2 from '../../classes/HybridNode2';
import { JSONArrayType } from '../datatypes/jsonArrayType';
import { JSONType } from '../datatypes/jsonType';
import { DynamicImport } from '../../utils/dynamicImport';

const inputSocketName = 'Input';
const rowObjectsNames = 'JSON Array';
const dataInputName = 'Data';
const columnMetaName = 'Column Meta';

const exportOptions = ['xlsx', 'csv', 'txt', 'html', 'rtf'];
export class Table2 extends HybridNode2 {
  //workBook: XLSX.WorkBook;

  // refreshes the UI table with new array of json input, function added by react component further down
  refreshTable = (number) => {};

  public getName(): string {
    return 'Table';
  }

  public getDescription(): string {
    return 'Table (Sheet/Excel) node';
  }

  public getTags(): string[] {
    return ['Input', 'CSV', 'Excel'].concat(super.getTags());
  }

  getPreferredInputSocketName(): string {
    return inputSocketName;
  }

  static async getXLSXModule(): Promise<typeof XLSX> {
    return await DynamicImport.dynamicImport('xlsx');
  }

  public onNodeAdded = async (source: TNodeSource): Promise<void> => {
    await super.onNodeAdded(source);

    if (this.initialData !== undefined) {
      this.setInputData(dataInputName, this.initialData);
    }
  };

  protected async onExecute(
    inputObject: any,
    outputObject: any,
  ): Promise<void> {
    super.onExecute(inputObject, outputObject);
    this.refreshTable(Math.random());
    this.setAllOutputData();
  }

  protected getDefaultIO(): PPSocket[] {
    return [
      new PPSocket(
        SOCKET_TYPE.OUT,
        rowObjectsNames,
        new JSONArrayType(),
        [],
        true,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        dataInputName,
        new JSONArrayType(),
        [
          { A: '', B: '' },
          { A: '', B: '' },
        ],
        true,
      ),
      new PPSocket(
        SOCKET_TYPE.IN,
        columnMetaName,
        new JSONType(),
        {
          B: {
            width: 120,
          },
          A: {
            width: 120,
          },
        },
        false,
      ),
    ];
  }

  getColor(): TRgba {
    return TRgba.fromString(NODE_TYPE_COLOR.INPUT);
  }

  public getMinNodeWidth(): number {
    return 200;
  }

  public getMinNodeHeight(): number {
    return 150;
  }

  public getDefaultNodeWidth(): number {
    return 800;
  }

  public getDefaultNodeHeight(): number {
    return 400;
  }

  public async onExport(selectedExportIndex: number) {
    const xlsx = await Table2.getXLSXModule();
    const workbook = xlsx.utils.book_new();
    const sheet = xlsx.utils.json_to_sheet(this.getInputData(dataInputName));
    xlsx.utils.book_append_sheet(workbook, sheet, 'Sheet');
    xlsx.writeFile(
      workbook,
      `${this.name}.${exportOptions[selectedExportIndex]}`,
      {
        sheet: workbook.SheetNames[0],
      },
    );
  }

  public setInputData(name: string, data: any): void {
    super.setInputData(name, data);
    this.refreshTable(Math.random());
    this.setAllOutputData();
  }

  public createNewRow(columns: string[]) {
    const newObject = {};
    columns.forEach((column) => {
      newObject[column] = '';
    });
    return newObject;
  }

  public addRow(rowObject: any) {
    const objects: any[] = this.getInputData(dataInputName);
    objects.push(rowObject);
    this.setInputData(dataInputName, objects);
  }

  public updateColumnOrder(columnName: string, newOrder: number, data: any[]) {
    const dataPost = [];
    if (data.length > 0) {
      const columns = Object.keys(data[0]);
      const otherColumns = columns.filter((column) => column !== columnName);

      data.forEach((oldObject) => {
        let otherColumnsIndex = 0;
        const newObject = {};
        for (; otherColumnsIndex < newOrder; otherColumnsIndex++) {
          newObject[otherColumns[otherColumnsIndex]] =
            oldObject[otherColumns[otherColumnsIndex]];
        }
        newObject[columnName] = oldObject[columnName];
        for (; otherColumnsIndex < otherColumns.length; otherColumnsIndex++) {
          newObject[otherColumns[otherColumnsIndex]] =
            oldObject[otherColumns[otherColumnsIndex]];
        }
        dataPost.push(newObject);
      });
    } else {
      console.error('cant change order of table, no input');
    }
    this.setInputData(dataInputName, dataPost);
  }

  public removeColumn(columnName: string) {
    const dataPre: any[] = this.getInputData(dataInputName);
    dataPre.forEach((obj) => delete obj[columnName]);
    this.setInputData(dataInputName, dataPre);
  }

  private static getAcceptableColumnName(
    desiredName: string,
    columns: string[],
  ): string {
    let currentNewColumnName = desiredName;
    let index = 2;
    while (columns.includes(currentNewColumnName)) {
      currentNewColumnName = desiredName + ' ' + index;
      index++;
    }
    return currentNewColumnName;
  }

  public addColumn(location: undefined | number = undefined) {
    const dataPre: any[] = this.getInputData(dataInputName);
    const currentNewColumnName = Table2.getAcceptableColumnName(
      'New',
      dataPre.length > 1 ? Object.keys(dataPre[0]) : [],
    );

    dataPre.forEach((object) => {
      object[currentNewColumnName] = '';
    });
    this.setInputData(dataInputName, dataPre);

    if (location !== undefined) {
      this.updateColumnOrder(currentNewColumnName, location, dataPre);
    }
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  public updateCellData(row, columnName, data) {
    const dataPre = this.getInputData(dataInputName);

    // convert to number if it seems like it is one
    if (this.isNumeric(data)) {
      data = Number(data);
    }

    dataPre[row][columnName] = data;
    this.setInputData(dataInputName, dataPre);
  }

  public updateColumnName(oldName: string, newName: string) {
    const dataPre: any[] = this.getInputData(dataInputName);
    if (dataPre.length > 0) {
      const prevIndex = Object.keys(dataPre[0]).indexOf(oldName);
      // dont allow colliding names
      const usedNewName = Table2.getAcceptableColumnName(
        newName,
        Object.keys(dataPre[0]),
      );
      dataPre.forEach((object) => {
        object[usedNewName] = object[oldName];
        delete object[oldName];
      });
      this.setInputData(dataInputName, dataPre);
      this.updateColumnOrder(usedNewName, prevIndex, dataPre);
    } else {
      console.error('cannot change column name, no input data');
    }
  }

  public updateColumnMeta(columnName, fieldName, data) {
    const columnMeta = this.getInputData(columnMetaName);
    const newMeta =
      columnMeta[columnName] !== undefined ? columnMeta[columnName] : {};
    newMeta[fieldName] = data;
    columnMeta[columnName] = newMeta;
    this.setInputData(columnMetaName, columnMeta);
    this.refreshTable(Math.random());
  }

  public getColumns(tableData: any[]): [string[], GridColumn[]] {
    const columnMeta = this.getInputData(columnMetaName);
    const tableColumns: string[] =
      tableData.length > 0 ? Object.keys(tableData[0]) : [];
    let gridColumns: GridColumn[] = tableColumns.map((column) => ({
      title: column,
      id: column,
    }));
    gridColumns = gridColumns.map((column) => ({
      ...column,
      ...columnMeta[column.title],
    }));
    return [tableColumns, gridColumns];
  }

  public static JSONArrayToArrayOfArrays(JSONArray: any[]): any[][] {
    if (JSONArray.length < 1) {
      return [];
    } else {
      const columns = Object.keys(JSONArray[0]);
      const toReturn: any[][] = [];

      const columnsArray = [];
      for (let j = 0; j < columns.length; j++) {
        columnsArray.push(columns[j]);
      }
      toReturn.push(columnsArray);
      for (let i = 0; i < JSONArray.length; i++) {
        const newArray = [];
        for (let j = 0; j < columns.length; j++) {
          newArray.push(JSONArray[i][columns[j]]);
        }
        toReturn.push(newArray);
      }

      return toReturn;
    }
  }

  public static flipArrayOfArrays(arrayOfArrays: any[][]) {
    const newArrayOfArrays = [];
    if (arrayOfArrays.length > 0) {
      const lenX = arrayOfArrays.length;
      const lenY = arrayOfArrays[0].length;
      for (let i = 0; i < lenY; i++) {
        const newArray = [];
        for (let j = 0; j < lenX; j++) {
          newArray.push(arrayOfArrays[j][i]);
        }
        newArrayOfArrays.push(newArray);
      }
    }
    return newArrayOfArrays;
  }

  public static arrayOfArraysToJSONArray(arrayOfArrays: any[][]) {
    if (arrayOfArrays.length < 1) {
      return {};
    } else {
      const columns = arrayOfArrays[0];
      const toReturn = [];
      for (let i = 1; i < arrayOfArrays.length; i++) {
        const newObject = {};
        for (let j = 0; j < columns.length; j++) {
          newObject[columns[j]] = arrayOfArrays[i][j];
        }
        toReturn.push(newObject);
      }
      return toReturn;
    }
  }

  getParentComponent(props: any): React.ReactElement {
    const node = props.node;

    const ref = useRef<DataEditorRef | null>(null);
    const [colMenu, setColMenu] = useState<{
      col: number;
      pos: PIXI.Point;
    }>();
    const [rowMenu, setRowMenu] = useState<{
      cell: Item;
      pos: PIXI.Point;
    }>();

    const readonly = node.getInputSocketByName(dataInputName).hasLink();

    const [hoverRow, setHoverRow] = useState<number | undefined>(undefined);
    const [openExportFormat, setExportFormatOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedExportIndex, setSelectedExportIndex] = useState(0);
    const [refreshValue, setRefreshValue] = useState(0);
    node.refreshTable = setRefreshValue;
    const tableContent = node.getInputData(dataInputName);

    const [tableColumns, gridColumns] = node.getColumns(tableContent);
    const tableData: any[] = tableContent;

    const handleExportFormatClose = (event: Event) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setExportFormatOpen(false);
    };

    const handleExportFormatToggle = () => {
      setExportFormatOpen((prevOpen) => !prevOpen);
    };

    const handleExportFormatClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      index: number,
    ) => {
      setSelectedExportIndex(index);
      setExportFormatOpen(false);
    };

    const onItemHovered = useCallback((args: GridMouseEventArgs) => {
      const [_, row] = args.location;
      setHoverRow(args.kind !== 'cell' ? undefined : row);
    }, []);

    const getRowThemeOverride = useCallback(
      (row) => {
        if (row !== hoverRow) return undefined;
        return {
          bgCell: '#f7f7f7',
          bgCellMedium: '#f0f0f0',
        };
      },
      [hoverRow],
    );

    const isColOpen = colMenu !== undefined;
    const isRowOpen = rowMenu !== undefined;

    useEffect(() => {
      console.log(props, dataInputName, props[dataInputName]);
    }, []);

    useEffect(() => {
      if (props.doubleClicked) {
        ref.current.focus();
      }
    }, [props.doubleClicked]);

    useEffect(() => {
      node.refreshTable(Math.random());
    });

    const getContent = useCallback(
      (cell: Item): GridCell => {
        const [col, row] = cell;
        let dataToDisplay = '';
        let data = '';
        if (
          col < tableColumns.length + 1 &&
          row < tableData.length + 1 &&
          row >= -1 &&
          col >= 0
        ) {
          if (row == 0) {
            data = tableColumns[col];
          } else {
            data = tableData[row - 1][tableColumns[col]];
          }
          dataToDisplay = String(data ?? '');
        }
        return {
          kind: GridCellKind.Text,
          allowOverlay: true,
          allowWrapping: true,
          readonly: readonly,
          displayData: dataToDisplay,
          data: data,
        };
      },
      [tableColumns, tableData, gridColumns],
    );

    const onPaste = useCallback(
      (target: Item, values: readonly (readonly string[])[]) => {
        const rowDifference = target[0] + values.length - tableData.length;
        for (let i = 0; i < rowDifference; i++) {
          node.addRow(node.createNewRow(tableColumns));
        }
        node.refreshTable(Math.random());
        return true;
      },
      [],
    );

    const onCellEdited = (cell: Item, newValue: EditableGridCell) => {
      const [col, row] = cell;
      if (row == 0) {
        node.updateColumnName(tableColumns[col], newValue.data);
      } else {
        node.updateCellData(row - 1, tableColumns[col], newValue.data);
      }
    };

    const onColumnResize = useCallback(
      (column: GridColumn, newSize: number) => {
        node.updateColumnMeta(column.title, 'width', newSize);
      },
      [],
    );

    const onColumnMoved = useCallback(
      (startIndex: number, endIndex: number): void => {
        console.log(
          'index: ' +
            startIndex +
            ' updating: ' +
            tableColumns[startIndex] +
            ' to position: ' +
            endIndex,
        );
        node.updateColumnOrder(
          tableColumns[startIndex],
          endIndex,
          node.getInputData(dataInputName),
        );
      },
      [tableColumns],
    );

    const onRowMoved = useCallback(
      (from: number, to: number) => {
        if (from == 0 || to == 0) {
          console.warn('not allowing top row exchange');
        } else {
          from -= 1;
          to -= 1;
          const pre = tableData;
          const row = tableData[from];
          pre.splice(from, 1);
          pre.splice(to, 0, row);
          node.setInputData(dataInputName, pre);
        }
      },
      [tableData],
    );

    const onSort = (columnIndex: number, desc: boolean) => {
      tableData.sort((a, b) =>
        sortCompare(
          a[tableColumns[columnIndex]],
          b[tableColumns[columnIndex]],
          desc,
        ),
      );
      node.setInputData(dataInputName, tableData);
    };

    const onHeaderMenuClick = useCallback((col: number, bounds: Rectangle) => {
      setColMenu({
        col,
        pos: new PIXI.Point(bounds.x + bounds.width, bounds.y),
      });
    }, []);

    const onHeaderContextMenu = useCallback(
      (col: number, event: HeaderClickedEventArgs) => {
        event.preventDefault();
        setColMenu({
          col,
          pos: new PIXI.Point(
            event.bounds.x + event.localEventX,
            event.bounds.y + event.localEventY,
          ),
        });
      },
      [],
    );

    const onContextMenuClick = useCallback(
      (cell: Item, event: CellClickedEventArgs) => {
        event.preventDefault();
        setRowMenu({
          cell,
          pos: new PIXI.Point(
            event.bounds.x + event.localEventX,
            event.bounds.y + event.localEventY,
          ),
        });
      },
      [],
    );

    return (
      <Box sx={{ position: 'relative', height: '100%' }}>
        {props.doubleClicked && (
          <ThemeProvider theme={customTheme}>
            <ButtonGroup
              variant="contained"
              size="small"
              ref={anchorRef}
              sx={{
                position: 'absolute',
                bottom: '8px',
                right: '8px',
                zIndex: 10,
              }}
            >
              <Button
                size="small"
                onClick={handleExportFormatToggle}
                sx={{ px: 1 }}
              >
                {exportOptions[selectedExportIndex]}
              </Button>
              <Button onClick={() => node.onExport(selectedExportIndex)}>
                <DownloadIcon sx={{ ml: 0.5, fontSize: '16px' }} />{' '}
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={openExportFormat}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              placement="top-start"
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleExportFormatClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {exportOptions.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={index === selectedExportIndex}
                            onClick={(event) =>
                              handleExportFormatClick(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </ThemeProvider>
        )}
        <DataEditor
          ref={ref}
          getCellContent={getContent}
          columns={gridColumns}
          rows={tableData.length + 1}
          overscrollX={40}
          maxColumnAutoWidth={500}
          maxColumnWidth={2000}
          onColumnResize={onColumnResize}
          width="100%"
          height="100%"
          getRowThemeOverride={getRowThemeOverride}
          onCellEdited={onCellEdited}
          onCellContextMenu={onContextMenuClick}
          onColumnMoved={onColumnMoved}
          onHeaderContextMenu={onHeaderContextMenu}
          onHeaderMenuClick={onHeaderMenuClick}
          onItemHovered={onItemHovered}
          onPaste={onPaste}
          onRowAppended={() => {
            node.addRow(node.createNewRow(tableColumns));
          }}
          onRowMoved={onRowMoved}
          fillHandle={true}
          rowSelect="multi"
          rowMarkers={'both'}
          smoothScrollX={true}
          smoothScrollY={true}
          rowSelectionMode="multi"
          getCellsForSelection={true}
          keybindings={{ search: true }}
          trailingRowOptions={{
            sticky: true,
            tint: true,
            hint: 'New row...',
          }}
          rightElement={
            <Box
              sx={{
                width: '40px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#f1f1f1',
              }}
            >
              <IconButton
                sx={{ pt: '4px' }}
                size="small"
                onClick={() => {
                  node.addColumn();
                }}
              >
                <AddIcon sx={{ fontSize: '16px' }} />
              </IconButton>
            </Box>
          }
          rightElementProps={{
            fill: false,
            sticky: false,
          }}
        />
        <Menu
          open={isColOpen}
          onClose={() => {
            setColMenu(undefined);
          }}
          anchorReference="anchorPosition"
          anchorPosition={{
            top: colMenu?.pos.y ?? 0,
            left: colMenu?.pos.x ?? 0,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Divider />
          <MenuItem
            onClick={() => {
              onSort(colMenu.col, false);
              setColMenu(undefined);
            }}
          >
            <ListItemIcon>
              <SortIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Sort A-Z (no undo!)</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onSort(colMenu.col, true);
              setColMenu(undefined);
            }}
          >
            <ListItemIcon>
              <SortIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Sort Z-A (no undo!)</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              node.addColumn(Math.max(colMenu.col - 1, 0));
            }}
          >
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add column left</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              node.addColumn(colMenu.col + 1);
            }}
          >
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add column right</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              node.removeColumn(tableColumns[colMenu.col]);
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete column</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              const aoa = Table2.JSONArrayToArrayOfArrays(tableData);
              const flipped = Table2.flipArrayOfArrays(aoa);
              const jsonArray = Table2.arrayOfArraysToJSONArray(flipped);
              node.setInputData(dataInputName, jsonArray);
              node.executeOptimizedChain();
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Flip rows and columns (!)</ListItemText>
          </MenuItem>
        </Menu>
        <Menu
          open={isRowOpen}
          onClose={() => {
            setRowMenu(undefined);
          }}
          anchorReference="anchorPosition"
          anchorPosition={{
            top: rowMenu?.pos.y ?? 0,
            left: rowMenu?.pos.x ?? 0,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Divider />
          <MenuItem
            onClick={() => {
              const rowsNum = tableData.length;
              node.addRow(node.createNewRow(tableColumns));
              onRowMoved(rowsNum, rowMenu.cell[1]);
            }}
          >
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add row above</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              const rowsNum = tableData.length;
              node.addRow(node.createNewRow(tableColumns));
              onRowMoved(rowsNum, rowMenu.cell[1] + 1);
            }}
          >
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add row below</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              tableData.splice(Math.max(rowMenu.cell[1] - 1, 0), 1);
              node.setInputData(dataInputName, tableData);
              setRowMenu(undefined);
            }}
          >
            <ListItemIcon></ListItemIcon>
            <ListItemText>Delete row</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    );
  }

  setAllOutputData(): any {
    this.setOutputData(rowObjectsNames, this.getInputData(dataInputName));
    this.executeChildren();
  }

  // returns an array of JSON arrays, to be use for table nodes
  public static async convertArrayBufferToTableInput(
    input: any,
  ): Promise<any[][]> {
    const xlsx = await Table2.getXLSXModule();
    if (input !== undefined) {
      const workBook: XLSX.WorkBook = xlsx.read(input);
      return workBook.SheetNames.map((name) =>
        xlsx.utils.sheet_to_json(workBook.Sheets[name], {
          raw: false,
        }),
      );
    } else {
      return [];
    }
  }
}
