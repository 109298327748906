import React from 'react';
import * as PIXI from 'pixi.js';
import { AbstractType, Compatibility, CompatibilityType } from './abstractType';
import { AnyType } from './anyType';

// its a composite type, a function that will draw onto a container, and a hash (so that the caller knows if it needs to be redrawn)
export interface DeferredPixiTypeInterface {
  drawFunction: (
    container: PIXI.Container,
    position: PIXI.Point,
    callChain: string,
    passedInOverrideSettings: any,
  ) => Promise<void>;
  hash: number;
}

export class DeferredPixiType extends AbstractType {
  getInputWidget = (props: any): any => {
    return <></>;
  };

  getOutputWidget = (props: any): any => {
    return <></>;
  };

  getDefaultWidgetSize(): any {
    return {
      w: 2,
      h: 1,
      minW: 1,
      minH: 1,
    };
  }

  getName(): string {
    return 'Deferred Pixi';
  }

  // TODO replace this with something more interesting (maybe drawing something like an image?)
  getDefaultValue(): DeferredPixiTypeInterface {
    return { drawFunction: async () => {}, hash: 0 };
  }

  getComment(commentData: any): string {
    return commentData ? 'Graphics' : 'null';
  }

  recommendedOutputNodeWidgets(): string[] {
    return [
      'DRAW_COMBINE_ARRAY',
      'DRAW_Combine',
      'DRAW_Passthrough',
      'DRAW_Multiplier',
      'DRAW_Multipy_Along',
      'Extract_Image_From_Graphics',
      'Extract_PixelArray_From_Graphics',
    ];
  }

  recommendedInputNodeWidgets(): string[] {
    return [
      'DRAW_Shape',
      'DRAW_Text',
      'DRAW_Line',
      'DRAW_Image',
      'DRAW_Polygon',
    ];
  }
  // cannot save this
  prepareDataForSaving(data: any) {
    return undefined;
  }

  protected dataIsCompatible(
    data: any,
    convertFrom: AbstractType = new AnyType(),
  ): Compatibility {
    return data != undefined &&
      typeof data.drawFunction == 'function' &&
      typeof data.hash == 'number'
      ? new Compatibility(CompatibilityType.Compatible)
      : new Compatibility(CompatibilityType.Incompatible);
  }

  static stringToHash(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }
}
