import * as PIXI from 'pixi.js';
import UpdateBehaviourClass from './UpdateBehaviourClass';
import { NODE_MARGIN, MAIN_COLOR, TEXT_RESOLUTION, UPDATEBEHAVIOURHEADER_NOUPDATE, UPDATEBEHAVIOURHEADER_TEXTSTYLE, UPDATEBEHAVIOURHEADER_UPDATE } from '../utils/constants';
import { TRgba } from '../utils/interfaces';
import PPNode from './NodeClass';
export default class UpdateBehaviourGraphics extends PIXI.Container {
  _frequencyRef: PIXI.Text;
  _updateRef: PIXI.Sprite;
  _noUpdateRef: PIXI.Sprite;
  private _hover: boolean;
  private _hoverNode: boolean;
  private initialized = false;

  updateBehaviour: UpdateBehaviourClass;

  constructor(updateBehaviour: UpdateBehaviourClass){
    super();
    this.updateBehaviour = updateBehaviour;
  }
  // SETUP

  get hover(): boolean {
    return this._hover;
  }

  set hover(isHovering: boolean) {
    this._hover = isHovering;
    this.redrawAnythingChanging();
  }


  redrawAnythingChanging(): void {
    if (this.initialized) {
      // reset
      this._updateRef.alpha = 0.05;
      this._noUpdateRef.visible = false;
      this._frequencyRef.text = '';

      // update and noupdate
      if (this.hover) {
        this._updateRef.alpha = 1.0;
      } else if (this.updateBehaviour.getNode()?.isHovering) {
        this._updateRef.alpha = 0.5;
      } else if (!this.updateBehaviour.update) {
        this._noUpdateRef.visible = true;
      }

      // frequency text
      if (this.updateBehaviour.interval) {
        this._frequencyRef.text = this.updateBehaviour.intervalFrequency.toString();
      }
    }
  }


  onPointerOver(): void {
    this.hover = true;
  }

  onPointerOut(): void {
    this.cursor = 'default';
    this.hover = false;
  }

  onPointerDown(): void {
    this.updateBehaviour.getNode().executeOptimizedChain();
  }

  async init(): Promise<void> {
    const FrequencyText = new PIXI.Text({
      text: this.updateBehaviour.intervalFrequency.toString(),
      style: UPDATEBEHAVIOURHEADER_TEXTSTYLE,
    });
    FrequencyText.x = 26;
    FrequencyText.y = 6;
    FrequencyText.resolution = TEXT_RESOLUTION;
    FrequencyText.alpha = 0.5;

    const myColor = TRgba.fromString(MAIN_COLOR).darken(0.7).hex();

    this._frequencyRef = this.addChild(FrequencyText);
    this._frequencyRef.tint = new PIXI.Color(myColor).toNumber();

    await PIXI.Assets.load(UPDATEBEHAVIOURHEADER_UPDATE);
    this._updateRef = this.addChild(
      PIXI.Sprite.from(UPDATEBEHAVIOURHEADER_UPDATE),
    );
    this._updateRef.tint = new PIXI.Color(myColor).toNumber();

    await PIXI.Assets.load(UPDATEBEHAVIOURHEADER_NOUPDATE);
    this._noUpdateRef = this.addChild(
      PIXI.Sprite.from(UPDATEBEHAVIOURHEADER_NOUPDATE),
    );
    this._noUpdateRef.visible = false;
    this._noUpdateRef.tint = new PIXI.Color(myColor).toNumber();

    this.addChild(this._updateRef);
    this.addChild(this._noUpdateRef);

    this._updateRef.eventMode = 'static';
    this._updateRef.cursor = 'pointer';
    this._updateRef.alpha = 0.05;
    this._updateRef.x = 0;
    this._updateRef.width = 24;
    this._updateRef.height = 24;

    this._updateRef.addEventListener(
      'pointerover',
      this.onPointerOver.bind(this),
    );
    this._updateRef.addEventListener(
      'pointerout',
      this.onPointerOut.bind(this),
    );
    this._updateRef.addEventListener(
      'pointerdown',
      this.onPointerDown.bind(this),
    );

    this._noUpdateRef.width = 24;
    this._noUpdateRef.height = 24;

    this.redrawAnythingChanging();

    if (this.updateBehaviour.getNode().getShouldShowHoverActions()) {
      this.updateBehaviour.getNode()._BackgroundRef.addChild(this);
    }
    this.x = NODE_MARGIN;
    this.y = -24;

    this.initialized = true;
  }
}
