// pnp-worker.ts
import { ComputeMessage, ComputeResult } from './compute-worker';

export class PNPWorker {
  private worker: Worker;

  constructor() {
    this.worker = new Worker(new URL('compute-worker.ts', import.meta.url));
  }

  public work(
    message: ComputeMessage,
    timeout: number = 10000,
  ): Promise<ComputeResult> {
    return new Promise((resolve, reject) => {
      const handleMessage = (e: MessageEvent<ComputeResult>) => {
        this.worker.removeEventListener('message', handleMessage);
        resolve(e.data);
      };

      this.worker.addEventListener('message', handleMessage);
      this.worker.postMessage(message);

      setTimeout(() => {
        this.worker.terminate();
        reject(new Error('Compute operation timed out'));
      }, timeout);
    });
  }
}
