import React, { useEffect, useState } from 'react';
import { Box, Button, styled } from '@mui/material';
import PPGraph from '../classes/GraphClass';
import PPNode from '../classes/NodeClass';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import GraphOverlayDrawer from './GraphOverlayDrawer';
import GraphOverlayDashboard from './GraphOverlayDashboard';
import { useIsSmallScreen } from '../utils/utils';
import { TRgba } from '../utils/interfaces';
import {
  CONTEXTMENU_GRAPH_HEIGHT,
  CONTEXTMENU_WIDTH,
  DEFAULT_DASHBOARD_WIDTH_PERCENTAGE,
  NarrowIcon,
  PNPIconNoShadow,
  WidenIcon,
} from '../utils/constants';

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<any>(({ theme, isSelected }) => ({
  minWidth: 0,
  padding: theme.spacing(0.5),
  borderRadius: '24px',
  backgroundColor: isSelected ? theme.palette.action.selected : 'transparent',
  '&:hover': {
    backgroundColor: isSelected
      ? theme.palette.action.selected
      : theme.palette.action.hover,
  },
}));

function PlaygroundButtonGroup(props) {
  const [showDashboard, setShowDashboard] = useState(false);
  const [showBright, setShowBright] = useState(false);
  const smallScreen = useIsSmallScreen();

  const posX = smallScreen ? 8 : window.innerWidth / 2 - CONTEXTMENU_WIDTH / 2;
  const posY = smallScreen
    ? 8
    : window.innerHeight - CONTEXTMENU_GRAPH_HEIGHT - 88;

  useEffect(() => {
    setShowDashboard(PPGraph.currentGraph?.showDashboard);
  }, [PPGraph.currentGraph?.showDashboard]);

  useEffect(() => {
    if (PPGraph.currentGraph) {
      setShowBright(
        PPGraph.currentGraph?.showDashboard &&
          (PPGraph.currentGraph?.dashboardSplitPercentage > 50 || smallScreen),
      );
    }
  }, [
    PPGraph.currentGraph?.showDashboard,
    PPGraph.currentGraph?.dashboardSplitPercentage,
    smallScreen,
  ]);

  const openContextMenuClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    console.log('openContextMenuClick');
    event.stopPropagation();
    if (showDashboard) {
      InterfaceController.toggleShowDashboard(false);
      setShowDashboard((prev) => !prev);
    } else {
      props.setContextMenuPosition([posX, posY]);
      props.setIsGraphContextMenuOpen((isOpen) => !isOpen);
      InterfaceController.toggleLeftSideDrawer(false);
      InterfaceController.toggleRightSideDrawer(false);
    }
  };

  const openDashboardClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    console.log('openDashboardClick');
    event.stopPropagation();
    InterfaceController.toggleShowDashboard();
    setShowDashboard((prev) => !prev);
  };

  return (
    <Box
      id="playground-button-group"
      sx={{
        '--svg-fill-color': showBright
          ? TRgba.white().hex()
          : TRgba.fromString(props.randomMainColor).hex(),
        position: 'fixed',
        bottom: '-6px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1300',
        background: showBright
          ? TRgba.fromString(props.randomMainColor).setAlpha(0.3).hexa()
          : 'unset',
        borderRadius: !smallScreen && showDashboard ? '24px 0 0 24px' : '24px',
      }}
    >
      <StyledButton
        title="Toggle dashboard (2)"
        data-cy="toggle-dashboard-btn"
        onClick={smallScreen ? openContextMenuClick : openDashboardClick}
      >
        <PNPIconNoShadow />
      </StyledButton>
      {!smallScreen && showDashboard && (
        <Box
          sx={{
            position: 'absolute',
            left: '100%',
            top: 0,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            background: showBright
              ? TRgba.fromString(props.randomMainColor).setAlpha(0.3).hexa()
              : 'unset',
            borderRadius: '0 24px 24px 0',
            paddingRight: '4px',
          }}
        >
          {props.dashboardWidthPercentage === 100 ? (
            <StyledButton
              title="Shrink dashboard"
              data-cy="shrink-dashboard-btn"
              onClick={(event) => {
                event.stopPropagation();
                InterfaceController.setDashboardSplitPercentage(38);
              }}
            >
              <NarrowIcon />
            </StyledButton>
          ) : (
            <StyledButton
              title="Maximise dashboard"
              data-cy="maximize-dashboard-btn"
              onClick={(event) => {
                event.stopPropagation();
                InterfaceController.setDashboardSplitPercentage(100);
              }}
            >
              <WidenIcon />
            </StyledButton>
          )}
        </Box>
      )}
    </Box>
  );
}

type GraphOverlayProps = {
  randomMainColor: string;
  toggle: boolean;
  toggleLeft: boolean;
  setContextMenuPosition: React.Dispatch<React.SetStateAction<number[]>>;
  setIsGraphContextMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const GraphOverlay: React.FunctionComponent<GraphOverlayProps> = (props) => {
  const [currentGraph, setCurrentGraph] = useState(PPGraph.currentGraph);
  const [selectedNodes, setSelectedNodes] = useState<PPNode[]>([]);
  const [dashboardWidthPercentage, setDashboardWidthPercentage] = useState(
    () => {
      // Try to load the stored percentage, or use the default
      const storedPercentage =
        currentGraph?.dashboardSplitPercentage ||
        DEFAULT_DASHBOARD_WIDTH_PERCENTAGE;
      return storedPercentage;
    },
  );

  useEffect(() => {
    const ids = [];

    // Add a listener for graph changes
    const graphChangeListener = () => {
      if (PPGraph.currentGraph !== currentGraph) {
        setCurrentGraph(PPGraph.currentGraph);
      }
    };

    ids.push(
      InterfaceController.addListener(
        ListenEvent.GraphChanged,
        graphChangeListener,
      ),
    );
    ids.push(
      InterfaceController.addListener(
        ListenEvent.SelectionChanged,
        setSelectedNodes,
      ),
    );

    return () => {
      ids.forEach((id) => InterfaceController.removeListener(id));
    };
  }, []);

  return (
    <>
      <PlaygroundButtonGroup
        setContextMenuPosition={props.setContextMenuPosition}
        setIsGraphContextMenuOpen={props.setIsGraphContextMenuOpen}
        randomMainColor={props.randomMainColor}
        dashboardWidthPercentage={dashboardWidthPercentage}
        setDashboardWidthPercentage={setDashboardWidthPercentage}
      />
      <GraphOverlayDrawer
        toggle={props.toggle}
        toggleLeft={props.toggleLeft}
        selectedNodes={selectedNodes}
        randomMainColor={props.randomMainColor}
      />
      {currentGraph && (
        <GraphOverlayDashboard
          randomMainColor={props.randomMainColor}
          toggleLeft={props.toggleLeft}
          dashboardWidthPercentage={dashboardWidthPercentage}
          setDashboardWidthPercentage={setDashboardWidthPercentage}
        />
      )}
    </>
  );
};

export default GraphOverlay;
