/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-this-alias */

import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Box, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import PPGraph from './GraphClass';
import PPNode from './NodeClass';
import { DashboardWidgetHeader } from '../components/GraphOverlayDashboard';
import UpdateBehaviourClass from './UpdateBehaviourClass';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import * as styles from '../utils/style.module.css';
import {
  CustomArgs,
  Layoutable,
  TNodeId,
  TNodeSource,
  TRgba,
} from '../utils/interfaces';
import {
  PIXI_TRANSPARENT_ALPHA,
  NINE_SLICE_SHADOW,
  NODE_MARGIN,
  NODE_CORNERRADIUS,
  NODE_SOURCE,
  MAIN_COLOR,
} from '../utils/constants';

function pixiToContainerNumber(value: number) {
  return `${Math.round(value)}px`;
}

const blurAmount = 48;

const widgetSize = {
  w: 6,
  h: 6,
  minW: 1,
  minH: 2,
};

export default abstract class HybridNode2 extends PPNode implements Layoutable {
  root: Root;
  static: HTMLElement;
  staticRoot: Root;
  container: HTMLElement;
  initialData: any;
  shadowPlane: PIXI.NineSliceSprite;
  listenId: string[] = [];

  constructor(name: string, customArgs?: CustomArgs) {
    super(name, {
      ...customArgs,
    });

    this.initialData = customArgs?.initialData;
  }

  public async onNodeAdded(source: TNodeSource): Promise<void> {
    await PIXI.Assets.load(NINE_SLICE_SHADOW);
    const texture = PIXI.Texture.from(NINE_SLICE_SHADOW);
    this.shadowPlane = new PIXI.NineSliceSprite({
      texture,
      leftWidth: blurAmount,
      topHeight: blurAmount,
      rightWidth: blurAmount,
      bottomHeight: blurAmount,
    });
    this.addChildAt(this.shadowPlane, 0);
    await super.onNodeAdded(source);
    if (this.shouldFocusWhenNew() && source === NODE_SOURCE.NEW) {
      setTimeout(() => {
        this.onEditButtonClick();
        this.focus();
      }, 500);
    }
  }

  public shouldFocusWhenNew(): boolean {
    return false;
  }

  public focus(): void {}

  public getUpdateBehaviour(): UpdateBehaviourClass {
    return new UpdateBehaviourClass(true, true, false, 1000, this);
  }

  getDefaultWidgetSize() {
    return widgetSize;
  }

  getDashboardId(): TNodeId {
    return `NODE_${this.id}`;
  }

  getDashboardName(): string {
    return this.nodeName;
  }

  getDashboardWidget(index, randomMainColor): any {
    return (
      <DashboardHybridWidgetContainer
        property={this}
        index={index}
        randomMainColor={randomMainColor}
      />
    );
  }

  redraw({ screenX = 0, screenY = 0, scale = 1 }) {
    if (this.container.style.transform != `scale(${scale.toPrecision(3)})`) {
      this.container.style.transform = `scale(${scale.toPrecision(3)})`;
    }
    if (this.container.style.left != pixiToContainerNumber(screenX)) {
      this.container.style.left = pixiToContainerNumber(screenX);
    }
    if (this.container.style.top != pixiToContainerNumber(screenY)) {
      this.container.style.top = pixiToContainerNumber(screenY);
    }
  }

  // this function can be called for hybrid nodes, it
  // • creates a container component
  // • adds the onNodeDragOrViewportMove listener to it
  // • adds a react parent component with props
  createContainerComponent(reactProps, customStyles = {}): HTMLElement {
    const reactElement = document.createElement('div');
    this.container = document
      .getElementById('container')
      .appendChild(reactElement);
    this.root = createRoot(this.container!);
    this.container.id = `Container-${this.id}`;

    const scale = PPGraph.currentGraph.viewportScaleX;
    this.container.classList.add(styles.hybridContainer);
    Object.assign(this.container.style, customStyles);

    // set initial position
    this.container.style.width = `${this.nodeWidth}px`;
    this.container.style.height = `${this.nodeHeight}px`;
    this.container.style.transform = `scale(${scale}`;

    this.onNodeDragOrViewportMove = this.redraw;

    this.onViewportPointerUpHandler = this.onViewportPointerUp.bind(this);

    // when the Node is removed also remove the react component and its container
    this.onNodeRemoved = () => {
      this.removeContainerComponent(this.container, this.root);
    };

    // render react component
    this.renderReactComponent(
      {
        ...reactProps,
      },
      this.root,
      this,
    );

    this.refreshNodeDragOrViewportMove();

    return this.container;
  }

  abstract getParentComponent(inputObject: any): any;

  // the render method, takes a component and props, and renders it to the page
  renderReactComponent = (
    props: {
      [key: string]: any;
    },
    root = this.root,
    node: PPNode = this,
  ): void => {
    root.render(
      <>
        <this.getParentComponent
          initialData={this.initialData} // positioned before the props so it can be overwritten by them
          {...props}
          id={this.id}
          selected={this.selected}
          doubleClicked={this.doubleClicked}
          randomMainColor={MAIN_COLOR}
          node={node}
        />
        <HybridNodeOverlay
          id={this.id}
          doubleClicked={this.doubleClicked}
          getActivateByDoubleClick={this.getActivateByDoubleClick()}
          getAddToDashboard={this.getAddToDashboard()}
          isHovering={this.isHovering}
          onEditButtonClick={this.onEditButtonClick.bind(this)}
          onAddToDashboardButtonClick={this.onAddToDashboardButtonClick.bind(
            this,
          )}
        />
      </>,
    );
  };

  removeContainerComponent(container: HTMLElement, root: Root): void {
    root.unmount();
    document.getElementById('container').removeChild(container);
  }

  protected onHybridNodeEnter(): void {}
  protected onHybridNodeExit(): void {}

  setPosition(x: number, y: number, isRelative = false): void {
    super.setPosition(x, y, isRelative);
    this.onViewportMove(); // trigger this once, so the react components get positioned properly
  }

  onPointerClick(event: PIXI.FederatedPointerEvent): void {
    this.listenId.push(
      InterfaceController.addListener(
        ListenEvent.GlobalPointerUp,
        this.onViewportPointerUpHandler,
      ),
    );

    super.onPointerClick(event);
  }

  resizeAndDraw(
    width = this.nodeWidth,
    height = this.nodeHeight,
    maintainAspectRatio = false,
  ): void {
    super.resizeAndDraw(width, height, maintainAspectRatio);
    if (this.container) {
      this.container.style.width = `${width}px`;
      this.container.style.height = `${height}px`;
    }
    this.execute();
  }

  makeEditable(): void {
    // register hybrid nodes to listen to outside clicks
    this.onHybridNodeEnter();
    this.container.classList.add(styles.hybridContainerFocused);
    this.drawBackground();
    this.execute();
  }

  // needed so react is forced to rerender and get the isHovering state
  onPointerOver(): void {
    super.onPointerOver();
    // this.execute();
  }

  onPointerOut(): void {
    super.onPointerOut();
    // this.execute();
  }

  onEditButtonClick(): void {
    if (this.getActivateByDoubleClick()) {
      this.listenId.push(
        InterfaceController.addListener(
          ListenEvent.GlobalPointerUp,
          this.onViewportPointerUpHandler,
        ),
      );
      this.listenId.push(
        InterfaceController.addListener(
          ListenEvent.EscapeKeyUsed,
          this.onViewportPointerUpHandler,
        ),
      );
      this.doubleClicked = true;
      this.makeEditable();
    }
  }

  onAddToDashboardButtonClick(): void {
    InterfaceController.onAddToDashboard(this);
  }

  public onNodeDoubleClick = (event) => {
    // turn on pointer events for hybrid nodes so the react components become reactive
    if (this.getActivateByDoubleClick() && event.target === this) {
      this.makeEditable();
    }
  };

  onViewportPointerUp(): void {
    super.onViewportPointerUp();
    this.onHybridNodeExit();
    this.listenId.forEach((id) => InterfaceController.removeListener(id));
    // this allows to zoom and drag when the hybrid node is not selected
    this.container.classList.remove(styles.hybridContainerFocused);
    this.drawBackground();
    this.execute();
  }

  public getShrinkOnSocketRemove(): boolean {
    return false;
  }

  protected getActivateByDoubleClick(): boolean {
    return true;
  }

  protected getAddToDashboard(): boolean {
    return true;
  }

  protected getShowLabels(): boolean {
    return false;
  }

  protected async onExecute(
    inputObject: any,
    outputObject: any,
  ): Promise<void> {
    if (!this.container) {
      this.createContainerComponent(inputObject);
    } else {
      this.renderReactComponent(inputObject);
    }
  }

  getOpacity(): number {
    return PIXI_TRANSPARENT_ALPHA;
  }

  public drawBackground(): void {
    this._BackgroundGraphicsRef.roundRect(
      NODE_MARGIN,
      0,
      this.nodeWidth,
      this.nodeHeight,
      this.getRoundedCorners() ? NODE_CORNERRADIUS : 0,
    );
    if (this.doubleClicked) {
      this.shadowPlane.x = -blurAmount + NODE_MARGIN;
      this.shadowPlane.y = -blurAmount;
      this.shadowPlane.width = this.nodeWidth + blurAmount * 2;
      this.shadowPlane.height = this.nodeHeight + blurAmount * 2;
      this.shadowPlane.visible = true;
    } else {
      this.shadowPlane.visible = false;
    }
    this._BackgroundGraphicsRef.fill({
      color: this.getColor().hexNumber(),
      alpha: this.getOpacity(),
    });
  }

  onNodeRemoved = (): void => {
    this.listenId.forEach((id) => InterfaceController.removeListener(id));
  };
}

type HybridNodeOverlayProps = {
  id: string;
  doubleClicked: boolean;
  getActivateByDoubleClick: boolean;
  getAddToDashboard: boolean;
  isHovering: boolean;
  onEditButtonClick: () => void;
  onAddToDashboardButtonClick: () => void;
};

const HybridNodeOverlay: React.FunctionComponent<HybridNodeOverlayProps> = (
  props,
) => {
  return (
    !props.doubleClicked && (
      <>
        {props.getActivateByDoubleClick && (
          <Button
            data-cy={`${props.id}-edit-hybridnode-btn`}
            title={'Click to edit OR Double click node'}
            className={styles.hybridContainerEditButton}
            size="small"
            onClick={props.onEditButtonClick}
            color="primary"
            sx={{
              background: MAIN_COLOR,
              color: TRgba.fromString(MAIN_COLOR).getContrastTextColor().hex(),
            }}
          >
            <EditIcon sx={{ fontSize: '16px' }} />
          </Button>
        )}
        {props.getAddToDashboard && (
          <Button
            data-cy={`${props.id}-add-hybridnode-to-dashboard-btn`}
            title={'Add to dashboard'}
            className={styles.hybridContainerAddToDashboardButton}
            size="small"
            onClick={props.onAddToDashboardButtonClick}
            color="primary"
            sx={{
              background: MAIN_COLOR,
              color: TRgba.fromString(MAIN_COLOR).getContrastTextColor().hex(),
            }}
          >
            <DashboardCustomizeIcon sx={{ fontSize: '16px' }} />
          </Button>
        )}
      </>
    )
  );
};

type DashboardHybridWidgetContainerProps = {
  property: HybridNode2;
  index: number;
  randomMainColor: string;
};

export const DashboardHybridWidgetContainer: React.FunctionComponent<
  DashboardHybridWidgetContainerProps
> = (props) => {
  const [showDashboard, setShowDashboard] = useState(
    PPGraph.currentGraph.showDashboard,
  );

  useEffect(() => {
    setShowDashboard(PPGraph.currentGraph.showDashboard);
  }, [PPGraph.currentGraph.showDashboard]);

  return (
    <Box
      id={`inspector-node-${props.property.getName()}`}
      sx={{
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <DashboardWidgetHeader
        key={`SocketHeader-${props.property.getName()}`}
        property={props.property}
        selectedNode={props.property}
        shouldBeLocked={false}
      />
      <Box
        sx={{
          height: 'calc(100% - 24px)',
          overflow: 'auto',
        }}
      >
        <props.property.getParentComponent
          initialData={props.property.initialData} // positioned before the props so it can be overwritten by them
          {...HybridNode2.remapInput(props.property.inputSocketArray)}
          id={props.property.id}
          selected={props.property.selected}
          doubleClicked={props.property.doubleClicked}
          randomMainColor={props.randomMainColor}
          node={props.property}
          showDashboard={showDashboard}
          inDashboard={true}
        />
      </Box>
    </Box>
  );
};
