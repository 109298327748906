import React, {
  createRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import ClearIcon from '@mui/icons-material/Clear';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import {
  GridItemHTMLElement,
  GridStack,
  GridStackNode,
  GridStackOptions,
} from 'gridstack';
import PPNode from '../classes/NodeClass';
import InterfaceController, { ListenEvent } from '../InterfaceController';
import PPGraph from '../classes/GraphClass';
import PPSocket from '../classes/SocketClass';
import { ensureVisible, zoomToFitNodes } from '../pixi/utils-pixi';
import { useIsSmallScreen, getLayoutableElement } from '../utils/utils';
import {
  DEFAULT_DASHBOARD_WIDTH_PERCENTAGE,
  DEFAULT_DRAWER_WIDTH,
  ONCLICK_DOUBLECLICK,
  ONCLICK_TRIPPLECLICK,
} from '../utils/constants';
import { ILayoutItem, TRgba } from '../utils/interfaces';
import HybridNode2 from '../classes/HybridNode2';
import * as styles from '../utils/style.module.css';

const MIN_WIDTH_PERCENTAGE = 10;

interface GridStackItem extends GridStackNode {
  id: string;
}

const CodeSpan = ({ children }) => (
  <span
    style={{
      fontFamily: 'monospace',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      padding: '2px 4px',
      borderRadius: '3px',
      fontSize: '0.9em',
    }}
  >
    {children}
  </span>
);

const EmptyState: React.FC = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      textAlign: 'center',
    }}
  >
    <Typography variant="h5" gutterBottom>
      No widgets added
    </Typography>
    <Typography variant="body1" color="text.secondary" paragraph>
      Click the
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          mx: 0.5,
          verticalAlign: 'middle',
        }}
      >
        <DashboardCustomizeIcon fontSize="small" />
      </Box>
      icon to add nodes or sockets to this dashboard.
      <br />
      To add sockets you can also <CodeSpan>Shift+Click</CodeSpan> them
      directly.
    </Typography>
  </Box>
);

interface ItemProps {
  id: string;
  index: number;
  randomMainColor: string;
}

const Item: React.FC<ItemProps> = ({ id, index, randomMainColor }) => {
  const layoutableElement = getLayoutableElement(id);
  if (!layoutableElement) {
    return null;
  }

  return (
    <Box
      sx={{
        pointerEvents: 'auto',
        border: '0.5px solid',
        borderColor: TRgba.fromString(randomMainColor).darken(0.4).hex(),
        background: TRgba.fromString(randomMainColor).darken(0.7).hex(),
        height: '100%',
      }}
    >
      {layoutableElement.getDashboardWidget(index, randomMainColor)}
    </Box>
  );
};

interface ControlledStackProps {
  // items: GridStackItem[];
  // addItem: (item: GridStackItem) => void;
  // changeItems: (items: GridStackItem[]) => void;
  items: any[];
  randomMainColor: string;
}

const ControlledStack: React.FunctionComponent<ControlledStackProps> = ({
  items,
  randomMainColor,
}) => {
  const refs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
  const gridRef = useRef<GridStack | null>(null);
  const gridContainerRef = useRef<HTMLDivElement | null>(null);

  if (Object.keys(refs.current).length !== items.length) {
    items.forEach(({ id }) => {
      refs.current[id] = refs.current[id] || createRef<HTMLDivElement>();
    });
  }

  useLayoutEffect(() => {
    if (!gridRef.current) {
      initializeGrid();
    } else {
      rerenderGrid();
    }

    function rerenderGrid() {
      console.log('rerenderGrid');
      const grid = gridRef.current;
      const layout = items.map(
        (a) =>
          (refs?.current[a.id]?.current as any)?.gridstackNode || {
            ...a,
            el: refs?.current[a.id]?.current,
          },
      );
      (grid as any)._ignoreCB = true;
      grid.load(layout);
      delete (grid as any)._ignoreCB;
    }

    function initializeGrid() {
      const options: GridStackOptions = {
        float: false,
        cellHeight: 32,
        margin: 4,
        minRow: 1,
        column: 12,
        animate: false,
        handle: '.MyDragHandleClassName',
        resizable: {
          handles: 'e,se,s,sw,w',
        },
        columnOpts: {
          columnWidth: 100,
          layout: 'list',
        },
      };

      const grid = (gridRef.current = GridStack.init(
        options,
        gridContainerRef.current,
      ));

      grid.on(
        'added change removed',
        (ev: Event, changedItems: GridStackNode[]) => {
          // subscribes to the change and removed event
          let updatedLayout;
          if (gridRef.current && changedItems) {
            const currentLayout = gridRef.current.save(
              false,
            ) as GridStackItem[];
            if (ev.type === 'change') {
              // Update the changed items in the current layout
              updatedLayout = currentLayout.map((item) => {
                const changedItem = changedItems.find(
                  (changed) => changed.id === item.id,
                );
                if (changedItem) {
                  return {
                    id: changedItem.id,
                    x: changedItem.x,
                    y: changedItem.y,
                    w: changedItem.w,
                    h: changedItem.h,
                    minW: changedItem.minW,
                    minH: changedItem.minH,
                  };
                }
                return item;
              });
            } else {
              updatedLayout = currentLayout;
            }
            // store layout
            PPGraph.currentGraph.layouts.default = updatedLayout;
          }
        },
      );

      grid.on(
        'resizestop',
        (ev: Event, changedElement: GridItemHTMLElement) => {
          InterfaceController.notifyListeners(
            ListenEvent.DashboardItemResize,
            getLayoutableElement(changedElement.gridstackNode.id as any),
          );
        },
      );
    }
  }, [items]);

  return (
    <Box
      id="dashboard-content"
      style={{ width: '100%', marginRight: '10px', height: '100%' }}
    >
      <Box
        className="grid-stack"
        data-cy="dashboard"
        ref={gridContainerRef}
        sx={{ minHeight: '100%' }}
      >
        {items.length === 0 ? (
          <EmptyState />
        ) : (
          items.map((item) => {
            return (
              <div
                ref={refs.current[item.id]}
                key={item.id}
                className="grid-stack-item"
                data-gs-id={item.id}
                data-gs-w={item.w}
                data-gs-h={item.h}
                data-gs-x={item.x}
                data-gs-y={item.y}
              >
                <Box className="grid-stack-item-content">
                  <Item {...item} randomMainColor={randomMainColor} />
                </Box>
              </div>
            );
          })
        )}
      </Box>
    </Box>
  );
};

type GraphOverlayDashboardProps = {
  randomMainColor: string;
  toggleLeft: boolean;
  dashboardWidthPercentage: number;
  setDashboardWidthPercentage: React.Dispatch<React.SetStateAction<number>>;
};

const GraphOverlayDashboard: React.FunctionComponent<
  GraphOverlayDashboardProps
> = (props) => {
  const [dashboardLeft, setDashboardLeft] = useState(
    props.toggleLeft ? DEFAULT_DRAWER_WIDTH : 0,
  );
  const [showDashboard, setShowDashboard] = useState(
    PPGraph.currentGraph.showDashboard,
  );
  const [items, setItems] = useState<GridStackNode[]>([]);
  const resizeRef = useRef<HTMLDivElement>(null);
  const smallScreen = useIsSmallScreen();

  const pollDashboardItems = useCallback(() => {
    let hasChanged = false;
    const newItems = items.filter((item) => {
      if (getLayoutableElement(item.id)) {
        return true;
      } else {
        hasChanged = true;
        InterfaceController.onRemoveFromDashboard(item.id);
        return false;
      }
    });

    if (hasChanged) {
      InterfaceController.showSnackBar(
        'Related widgets have been removed from dashboard',
      );
      setItems(newItems);
    }
  }, [items]);

  useEffect(() => {
    const pollInterval = setInterval(pollDashboardItems, 1000);

    return () => {
      clearInterval(pollInterval);
    };
  }, [pollDashboardItems]);

  useEffect(() => {
    InterfaceController.onAddToDashboard = addToDashboard;
    InterfaceController.onRemoveFromDashboard = removeFromDashboard;
    InterfaceController.onDrawerSizeChanged = drawerSizeChanged;
    InterfaceController.toggleShowDashboard = (open) =>
      setShowDashboard((prev) => {
        const newValue = open ?? !prev;
        PPGraph.currentGraph.showDashboard = newValue;
        return newValue;
      });
    InterfaceController.setDashboardSplitPercentage = (percentage) => {
      PPGraph.currentGraph.dashboardSplitPercentage = percentage;
      props.setDashboardWidthPercentage(percentage);
    };
  }, []);

  useEffect(() => {
    if (PPGraph.currentGraph.layouts) {
      const newLayout: ILayoutItem[] = JSON.parse(
        JSON.stringify(PPGraph.currentGraph.layouts.default),
      );
      const validLayout = newLayout.filter((item) =>
        getLayoutableElement(item.id),
      );
      setItems(validLayout);
    }
  }, [PPGraph.currentGraph.layouts]);

  useEffect(() => {
    PPGraph.currentGraph.dashboardSplitPercentage =
      props.dashboardWidthPercentage || DEFAULT_DASHBOARD_WIDTH_PERCENTAGE;
  }, [props.dashboardWidthPercentage]);

  useEffect(() => {
    props.setDashboardWidthPercentage(
      PPGraph.currentGraph.dashboardSplitPercentage,
    );
  }, [PPGraph.currentGraph.dashboardSplitPercentage]);

  useEffect(() => {
    setShowDashboard(PPGraph.currentGraph.showDashboard);
  }, [PPGraph.currentGraph.showDashboard]);

  const addToDashboard = useCallback((itemToAdd: PPSocket | HybridNode2) => {
    const itemId = itemToAdd.getDashboardId();
    let size;

    if (itemToAdd instanceof PPSocket) {
      size = itemToAdd.isInput()
        ? itemToAdd.dataType.getInputWidgetSize()
        : itemToAdd.dataType.getOutputWidgetSize();
    } else {
      size = itemToAdd.getDefaultWidgetSize();
    }

    const newItem: GridStackNode = {
      id: itemId,
      w: size.w,
      h: size.h,
      minW: size.minW,
      minH: size.minH,
    };

    setItems((prevLayout) => {
      const itemExists = prevLayout.some((item) => item.id === itemId);

      if (!itemExists) {
        InterfaceController.showSnackBar('Added to dashboard');
        return [...prevLayout, newItem];
      } else {
        InterfaceController.showSnackBar('Already on dashboard', {
          variant: 'warning',
        });
      }

      return prevLayout;
    });

    InterfaceController.toggleShowDashboard(true);
  }, []);

  const removeFromDashboard = useCallback((itemId: string) => {
    setItems((prevLayout) => prevLayout.filter((item) => item.id !== itemId));
  }, []);

  const drawerSizeChanged = useCallback(
    (leftWidth: number, rightWidth: number) => {
      setDashboardLeft(leftWidth);
    },
    [],
  );

  const handleResize = useCallback(
    (e: React.PointerEvent) => {
      e.preventDefault();

      const startX = e.clientX;
      const startWidthPercentage = props.dashboardWidthPercentage;

      const handleMove = (moveEvent: PointerEvent) => {
        const deltaX = moveEvent.clientX - startX;
        const deltaPercentage = (deltaX / window.innerWidth) * 100;
        const newWidthPercentage = Math.trunc(
          Math.max(
            MIN_WIDTH_PERCENTAGE,
            Math.min(100, startWidthPercentage + deltaPercentage),
          ),
        );
        PPGraph.currentGraph.dashboardSplitPercentage = newWidthPercentage;
        props.setDashboardWidthPercentage(newWidthPercentage);
      };

      const handleUp = () => {
        document.removeEventListener('pointermove', handleMove);
        document.removeEventListener('pointerup', handleUp);
      };

      document.addEventListener('pointermove', handleMove);
      document.addEventListener('pointerup', handleUp);
    },
    [props.dashboardWidthPercentage],
  );

  return (
    <Box
      id="dashboard-container"
      sx={{
        padding: '8px',
        visibility: showDashboard ? 'visible' : 'hidden',
        position: 'absolute',
        height: '100vh !important',
        width: `${smallScreen ? '100' : props.dashboardWidthPercentage}%`,
        overflow: 'auto',
        left: `${dashboardLeft}px`,
        bgcolor: 'background.default',
        zIndex: 5,
        transition: 'left 0.3s ease-in-out',
      }}
    >
      <Box
        ref={resizeRef}
        onPointerDown={handleResize}
        className={styles.draggerLeft}
        sx={{
          bgcolor: 'background.paper',
          cursor: 'ew-resize',
          width: '4px',
          height: '100%',
        }}
      />
      <ControlledStack items={items} randomMainColor={props.randomMainColor} />
    </Box>
  );
};

export default GraphOverlayDashboard;

type DashboardWidgetHeaderProps = {
  property: PPSocket | HybridNode2;
  selectedNode: PPNode;
  shouldBeLocked: boolean;
};

export const DashboardWidgetHeader: React.FunctionComponent<
  DashboardWidgetHeaderProps
> = (props) => {
  const [hoverOverHeader, setHoverOverHeader] = useState(false);

  const handleMouseLeave = () => {
    setHoverOverHeader(false);
  };

  return (
    <Box
      className="MyDragHandleClassName"
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        width: '100%',
        color: 'text.secondary',
        justifyContent: 'space-between',
        height: '24px',
        userSelect: 'none',
      }}
      onPointerLeave={handleMouseLeave}
      onPointerEnter={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setHoverOverHeader(true);
        const nodeToJumpTo = props.selectedNode;
        if (nodeToJumpTo) {
          PPGraph.currentGraph.selection.drawSingleFocus(nodeToJumpTo);
        }
      }}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        const nodeToJumpTo = props.selectedNode;
        if (nodeToJumpTo) {
          if (event.detail === ONCLICK_DOUBLECLICK) {
            ensureVisible([nodeToJumpTo]);
            setTimeout(() => {
              PPGraph.currentGraph.selection.drawSingleFocus(nodeToJumpTo);
            }, 800);
          } else if (event.detail === ONCLICK_TRIPPLECLICK) {
            zoomToFitNodes([nodeToJumpTo], -0.5);
          }
        }
      }}
    >
      <Box
        title={`${props.property.getDashboardName()}`}
        data-cy={`widget of ${props.property.getDashboardId()}`}
        sx={{
          pl: 1,
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          cursor: 'move',
          width: 'calc(100% - 32px)',
        }}
      >
        {props.shouldBeLocked && (
          <LockIcon sx={{ fontSize: '12px', opacity: 0.4 }} />
        )}
        <Box
          sx={{
            fontSize: '12px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            opacity: hoverOverHeader ? 1 : 0.4,
          }}
        >
          {`${props.property.getDashboardName()}`}
        </Box>
      </Box>
      <Box sx={{ flex: '1' }}></Box>{' '}
      {hoverOverHeader && (
        <IconButton
          title="Remove from dashboard"
          size="small"
          onClick={() => {
            InterfaceController.onRemoveFromDashboard(
              props.property.getDashboardId(),
            );
          }}
          sx={{
            borderRadius: 0,
          }}
        >
          <ClearIcon sx={{ fontSize: '20px' }} />
        </IconButton>
      )}
    </Box>
  );
};

const MyHandle = React.forwardRef<HTMLInputElement, { handleAxis?: string }>(
  (props, ref) => {
    const { handleAxis, ...restProps } = props;
    return (
      <Box
        ref={ref}
        className={`react-resizable-handle react-resizable-handle-${handleAxis}`}
        {...restProps}
        sx={{
          zIndex: 100,
        }}
      />
    );
  },
);
