import PPNode from "../../classes/NodeClass";
import { NODE_CORNERRADIUS, NODE_MARGIN, NODE_SOURCE } from "../../utils/constants";
import { TNodeSource, TRgba } from "../../utils/interfaces";
import { AbstractType } from "../datatypes/abstractType";
import { AnyType } from "../datatypes/anyType";

export abstract class TypeConversionNode extends PPNode {

  // auto set values on first plug after placement, IF not coming from serialized
  hasAutoSetValues: boolean = false;
  shouldAutoSetValues: boolean = false;

  public getName(): string {
    return 'Type Convert';
  }

  public getDescription(): string {
    return 'Base Parent class for type conversions';
  }

  protected abstract getTypeIn(): AbstractType;
  protected abstract getTypeOut(): AbstractType;

  public getColor(): TRgba {
    return new TRgba(150,150,150);
  }
  public async inputPlugged(): Promise<void> {
    super.inputPlugged();
    if (this.shouldAutoSetValues && !this.hasAutoSetValues){
      this.autoSetDefaultValues();
      this.hasAutoSetValues = true;
    }
  }

  public async onNodeAdded(source: TNodeSource): Promise<void> {
    await super.onNodeAdded(source);
    if (source === NODE_SOURCE.NEWCONNECTED ||source === NODE_SOURCE.NEW){
      this.shouldAutoSetValues = true;
    } else{
      this.shouldAutoSetValues = false;
    }
  };

  // called after the first input data is set, DO override
  protected autoSetDefaultValues() : void{
  }

};
